import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, List, Progress, message, Spin, Modal, Divider } from 'antd';
import {
    EditOutlined,
    LoadingOutlined,
    UserOutlined,
    ShoppingCartOutlined,
    WhatsAppOutlined,
    HeartOutlined
} from '@ant-design/icons';

import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";

// API
import { addCourseToWishList, buyACourse, enrollToCourseContent, getAllUsersOfACourse, getCourseById, getCourseContentByRoleAndCourseId, getUsersCourses } from '../../../../Constants/Api/functions/function';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import nodata from '../../../../Assets/Images/nodata.png';
import video from '../../../../Assets/Images/video.png';
import files from '../../../../Assets/Images/file.png';
import free from '../../../../Assets/Images/free.png';

import './style.scss';
import CourseUsers from './CourseUsers';
import { setUsersCources } from '../../../../Reducers/usersCourcesReducer';

import buyImage from '../../../../Assets/Images/buynow.png'
import CourseAssessments from './CourseAssessments';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const Course = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEnrollLoading, setIsEnrollLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const courseId = useParams().id
    const [selectedContentId, setSelectedContentId] = useState();
    const [courseContents, setCourseContents] = useState();
    const [completedPercentage, setCompletedPercentage] = useState(0);
    const [data, setData] = useState()
    const [course, setCourse] = useState()
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allCources = useSelector(state => state.allCources.allCources);
    const usersCources = useSelector(state => state.usersCources.usersCources);
    const [userCources, setUserCources] = useState();
    const [isCourseUnlocked, setIsCourseUnlocked] = useState(false);
    const [isContentUnlocked, setIsContentUnlocked] = useState(false);
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);
    const [courseUsers, setCourseusers] = useState(null)
    const [openCourseUsers, setOpenCourseUsers] = useState(false)
    const [showEntrollPopupForFreeCourse, setShowEntrollPopupForFreeCourse] = useState(false)
    const [showEntrollPopupForPaidCources, setShowEntrollPopupForPaidCources] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if (allCources) {
            setCourse(allCources.find(item => item.id === courseId));
        } else {
            handleGetCourse();
        }
    }, [allCources]);

    useEffect(() => {
        if (userProfile?.role !== 'superadmin') {
            if (usersCources) {
                setUserCources(usersCources);
            } else {
                handleGetUserCources();
            }
        }
    }, [usersCources]);

    useEffect(() => {
        if (userProfile?.role === 'superadmin') {
            setIsCourseUnlocked(true);
        }
    }, [userProfile]);

    useEffect(() => {
        if (userCources && course) {
            const isCourseInUserCourses = userCources?.find(item => item.id === course.id);
            const lastVisit = localStorage.getItem('digital-academy-last-visit');
            if (lastVisit !== courseId) {

                if (!isCourseInUserCourses && course?.price === 0) {
                    setShowEntrollPopupForFreeCourse(true);

                } else {
                    setShowEntrollPopupForFreeCourse(false);
                }

                if (!isCourseInUserCourses && course?.price !== 0) {
                    setShowEntrollPopupForPaidCources(true);
                } else {
                    setShowEntrollPopupForPaidCources(false);
                }

                localStorage.removeItem('digital-academy-last-visit');

            } else {
                setShowEntrollPopupForFreeCourse(false);
                setShowEntrollPopupForPaidCources(false);
                localStorage.removeItem('digital-academy-last-visit');
            }
        }
    }, [userCources, course])

    useEffect(() => {
        try {
            if (courseContents) {
                const content = courseContents?.find(item => item.id === selectedContentId);
                if (content) {
                    setIsContentUnlocked(content?.isFree);
                }

                if (userCources) {
                    const userCourse = userCources.find(item => item.id === courseId);
                    if (userCourse) {
                        setIsContentUnlocked(true);
                    }
                }

                if (course?.price === 0) {
                    setIsContentUnlocked(true);
                    setIsCourseUnlocked(true);
                }

                if (userProfile?.role === 'superadmin') {
                    setIsContentUnlocked(true);
                    setIsCourseUnlocked(true);
                }

                if (userCources) {
                    const userCourse = userCources.find(item => item.id === courseId);
                    if (userCourse) {
                        setIsCourseUnlocked(true);
                    }
                }
            }
        } catch (error) {
            console.error('isContentUnlocked', error);
        }
    }, [courseContents, selectedContentId, userCources]);

    useEffect(() => {
        getCourseContents();

        if (userProfile?.role === 'superadmin') {
            getCourseUsers()
        }
    }, [])

    const handleGetUserCources = async () => {
        setIsLoading(true)
        try {
            const userCources = await getUsersCourses(userProfile?.id)

            setUserCources(userCources)
            dispatch(setUsersCources(userCources))
        } catch (error) {
            console.error('handleGetUserCources', error)
        }
        setIsLoading(false)
    }

    const handleGetCourse = async () => {
        setIsLoading(true);
        try {
            const response = await getCourseById(courseId);
            if (response) {
                setCourse(response[0]);
            }
        } catch (error) {
            console.error('handleGetCourse', error);
        }
        setIsLoading(false);
    }

    const getCourseUsers = async () => {
        setIsLoadingUsers(true)
        const response = await getAllUsersOfACourse(courseId)
        setCourseusers(response)
        setIsLoadingUsers(false)
    }


    const getCourseContents = async () => {
        setIsLoading(true);
        try {
            let response = await getCourseContentByRoleAndCourseId(courseId, userProfile.role, userProfile.id);
            if (response) {
                setCourseContents(response);

                response.sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });

                const completedContents = response.filter(item => item?.participation?.id);
                const percentage = (completedContents.length / response.length) * 100;
                setCompletedPercentage(percentage % 1 === 0 ? percentage : percentage.toFixed(1));

                setData(response.map((item, index) => {
                    return {
                        id: item.id,
                        title: item.title,
                        status: item?.participation?.id ? 'completed' : 'pending',
                        duration: item.duration
                    }
                }))

                const uncompletedContent = response.find(item => !item?.participation?.id);
                if (uncompletedContent) {
                    setSelectedContentId(uncompletedContent.id);
                } else {
                    setSelectedContentId(response[0]?.id);
                }
            }
        } catch (error) {
            console.error('getCourseContents', error);
        }
        setIsLoading(false);
    }

    const handleMarkAsLearned = async () => {
        setIsEnrollLoading(true);
        try {
            const response = await enrollToCourseContent(courseId, userProfile.id, selectedContentId);

            if (response && response.status === 200) {
                openMessage('success', 'Marked as learned');
                getCourseContents();
            } else {
                openMessage('error', 'Error occured while marking as learned');
            }
        } catch (error) {
            console.error('handleMarkAsLearned', error);
            openMessage('error', 'Error occured while marking as learned');
        }
        setIsEnrollLoading(false);
    }

    const openMessage = (type, content) => {
        messageApi.open({
            type: type,
            content: content,
        });
    };

    const hnadleEnrollToFreeCourse = async () => {
        setIsLoading(true);
        try {
            const values = {
                courseId: courseId,
                userId: userProfile.id,
                isPaid: false,
                isAddToPayments: false
            }

            const response = await buyACourse(values);

            if (response && response.status === 200) {
                openMessage('success', 'You have successfully enrolled to this course');
                getCourseContents();
            } else {
                openMessage('error', 'Error occured while enrolling to this course');
            }
        } catch (error) {
            console.error('hnadleEnrollToFreeCourse', error);
            openMessage('error', 'Error occured while enrolling to this course');
        }
        setIsLoading(false);
        setShowEntrollPopupForFreeCourse(false);
    }

    const isCourseInWishList = () => {
        const userWishList = userProfile?.courseWishlist;
        const wishlistIds = userWishList?.split(',');
        wishlistIds?.pop();

        return wishlistIds?.includes(courseId);
    }

    const handleAddToWishList = async () => {
        setIsLoading(true);
        try {
            const response = await addCourseToWishList(userProfile.id, courseId);
            if (response) {
                openMessage('success', 'Course added to wishlist');
            } else {
                openMessage('error', 'Error occured while adding to wishlist');
            }
        } catch (error) {
            console.error('handleAddToWishList', error);
            openMessage('error', 'Error occured while adding to wishlist');
        }

        setShowEntrollPopupForPaidCources(false);
        setIsLoading(false);
    }

    return (
        <div className='course'>
            {contextHolder}
            <Modal
                title=''
                footer={null}
                centered
                open={showEntrollPopupForFreeCourse}
                onOk={() => setShowEntrollPopupForFreeCourse(false)}
                onCancel={() => setShowEntrollPopupForFreeCourse(false)}
            >
                <div className='confirmEnrollPopup'>
                    <div className='image'>
                        <img src={free} alt='Buy Course' />
                    </div>
                    <h1>Enroll to this course for free</h1>
                    <p>
                        This course is free for you. You can enroll to this course for free. Do you want to enroll to this course?
                    </p>
                    <div className='actions'>
                        <Button
                            type='primary'
                            onClick={hnadleEnrollToFreeCourse}
                            icon={isLoading ? <LoadingOutlined /> : null}
                        >
                            Enroll
                        </Button>
                        <Button
                            type='default'
                            onClick={() => setShowEntrollPopupForFreeCourse(false)}
                        >
                            Cancel
                        </Button>
                        {
                            course?.whatsappGroupLink &&
                            <a href={course?.whatsappGroupLink} target='_blank' rel="noreferrer">
                                <Button type='primary' style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#25d366',
                                }}>
                                    <WhatsAppOutlined />
                                    Join Whatsapp Group
                                </Button>
                            </a>
                        }
                    </div>
                </div>
            </Modal>

            <Modal
                className='paidCoursePopup'
                title=''
                footer={null}
                centered
                open={showEntrollPopupForPaidCources}
                onOk={() => setShowEntrollPopupForPaidCources(false)}
                onCancel={() => setShowEntrollPopupForPaidCources(false)}
            >
                <div className='confirmEnrollPopup'>
                    <div className='image'>
                        {course?.videoUrl && <Plyr
                            source={{
                                type: "video",
                                sources: [
                                    {
                                        src: course?.videoUrl,
                                        provider: "youtube"
                                    }
                                ],
                            }}
                        />}
                    </div>
                    <h1>Enroll to this course</h1>
                    {!isCourseInWishList() ?
                        <p>
                            You can enroll to this course by buying it.
                            Or you can add this course to your wishlist and buy it later.
                        </p>
                        :
                        <p>
                            You can enroll to this course by buying it.
                        </p>
                    }
                    <div className='actions'>
                        <Link to={`/dashboard/cources/${courseId}/buy`}>
                            <Button
                                type='primary'
                                icon={<ShoppingCartOutlined />}
                            >

                                Buy This Course
                            </Button>
                        </Link>

                        {!isCourseInWishList() &&
                            <Button
                                type='default'
                                icon={<HeartOutlined />}
                                onClick={handleAddToWishList}
                            >
                                Add to Wishlist
                            </Button>
                        }
                        {
                            course?.whatsappGroupLink &&
                            <a href={course?.whatsappGroupLink} target='_blank' rel="noreferrer">
                                <Button type='primary' style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#25d366',
                                }}>
                                    <WhatsAppOutlined />
                                    Join Whatsapp Group
                                </Button>
                            </a>
                        }

                        <Button
                            type='link'
                            onClick={() => setShowEntrollPopupForPaidCources(false)}
                            style={{
                                padding: 0,
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            <Spin
                spinning={isLoading}
                tip="Loading..."
                size="large">
                <div className='course-continer'>
                    <div className='course-header'>
                        <div className='about-course'>
                            <h1 className='course-title'>{course?.name}</h1>

                            <h3>About This Course</h3>
                            <p className='course-description'>{course?.description}</p>
                        </div>
                        {userProfile?.role === 'superadmin' &&
                            <div className='course-header-right'>
                                <Button
                                    type='default'
                                    className='btn'
                                    onClick={() => setOpenCourseUsers(true)}
                                >
                                    <span>
                                        {courseUsers?.length}
                                    </span>
                                    {isLoadingUsers ? <LoadingOutlined /> : <UserOutlined />}
                                    <span>Users</span>
                                </Button>

                                <Link to={`/dashboard/cources/${courseId}/edit`}>
                                    <Button type='primary' className='btn'>
                                        <EditOutlined />
                                        <span>Edit Course</span>
                                    </Button>
                                </Link>
                            </div>}

                        {
                            (userProfile?.role === 'student' && !isCourseUnlocked) &&
                            <Link to={`/dashboard/cources/${courseId}/buy`}>
                                <Button
                                    type='primary'
                                    size='large'
                                    shape='round'
                                    icon={<ShoppingCartOutlined />}
                                >

                                    Buy This Course
                                </Button>
                            </Link>
                        }
                    </div>

                    <div className='strat-date-and-time'>
                        {course?.startDate &&
                            <div className='course-start'>
                                <h2>Course Start Date</h2>
                                <p>{course?.startDate}</p>
                            </div>
                        }

                        {course?.startTime &&
                            <div className='course-start'>
                                <h2>Start Time:</h2>
                                <p>{course?.startTime}</p>
                            </div>
                        }
                    </div>

                    {
                        course?.videoUrl &&
                        <div className='description-video'>
                            <Plyr
                                source={{
                                    type: "video",
                                    sources: [
                                        {
                                            src: course?.videoUrl,
                                            provider: "youtube"
                                        }
                                    ],
                                }}
                            />
                        </div>
                    }

                    {
                        course?.whatsappGroupLink &&
                        <div className='whatsapp-group'>
                            <h3>Join Our Whatsapp Group</h3>
                            <a href={course?.whatsappGroupLink} target='_blank' rel="noreferrer">
                                <Button type='primary' style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#25d366',
                                }}>
                                    <WhatsAppOutlined />
                                    Join Whatsapp Group
                                </Button>
                            </a>
                        </div>
                    }

                    <Divider />

                    {(isLoading || !courseContents?.length)
                        ?
                        <div className='no-data'>
                            {!isLoading &&
                                <div>
                                    <img
                                        src={nodata}
                                        alt='No Data' />
                                    <h1>Course Content Will be Available Soon</h1>
                                </div>}
                        </div>
                        :
                        <>
                            <div className='body'>
                                <div className='course-left'>

                                    {isContentUnlocked ?
                                        <span>
                                            <h2 className='content-title'>
                                                {
                                                    (() => {
                                                        const selectedContent = courseContents?.find(item => item.id === selectedContentId);
                                                        if (!selectedContent) return '';

                                                        return selectedContent.title;
                                                    })()
                                                }
                                            </h2>
                                            {
                                                (() => {
                                                    const selectedContent = courseContents?.find(item => item.id === selectedContentId);
                                                    if (!selectedContent) return <h1>No video available</h1>;

                                                    let videoURL = selectedContent?.videoUrl;

                                                    const videoSrc = {
                                                        type: "video",
                                                        sources: [
                                                            {
                                                                src: videoURL,
                                                                provider: "youtube"
                                                            }
                                                        ],
                                                    }

                                                    return videoURL ? (
                                                        <Plyr source={videoSrc} />
                                                    )
                                                        :
                                                        <div className='no-data-mini'>
                                                            {!isLoading &&
                                                                <div>
                                                                    <img
                                                                        src={video}
                                                                        alt='No Data' />
                                                                    <h1>Video Will be Available Soon</h1>
                                                                </div>
                                                            }
                                                        </div>
                                                })()
                                            }
                                            <h2>About This Lesson</h2>
                                            <p>
                                                {
                                                    (() => {
                                                        const selectedContent = courseContents?.find(item => item.id === selectedContentId);

                                                        if (!selectedContent) return '';

                                                        return selectedContent.description;
                                                    })()
                                                }
                                            </p>

                                            <h2>Course Materials</h2>
                                            <div className='drive-floder'>
                                                {
                                                    (() => {
                                                        const selectedContent = courseContents?.find(item => item.id === selectedContentId);
                                                        if (!selectedContent) return
                                                        <h1>No Drive Folder available</h1>;

                                                        let driveURL = selectedContent?.driveUrl;

                                                        return driveURL ? (
                                                            <iframe
                                                                className='drive-iframe'
                                                                title="Google Drive Folder"
                                                                src={driveURL}>
                                                            </iframe>
                                                        ) : <div className='no-data-mini'>
                                                            {!isLoading &&
                                                                <div>
                                                                    <img
                                                                        src={files}
                                                                        alt='No Data' />
                                                                    <h1>Files Will be Available Soon</h1>
                                                                </div>
                                                            }
                                                        </div>
                                                    })()
                                                }
                                            </div>

                                            {userProfile?.role === 'student' &&
                                                <div className='course-footer'>
                                                    <Button
                                                        loading={isEnrollLoading}
                                                        type='primary'
                                                        onClick={handleMarkAsLearned}
                                                        className={
                                                            courseContents?.find(item => item.id === selectedContentId)?.participation?.id ? 'completed' : ''
                                                        }
                                                        disabled={courseContents?.find(item => item.id === selectedContentId)?.participation?.id}
                                                    >
                                                        {
                                                            courseContents?.find(item => item.id === selectedContentId)?.participation?.id
                                                                ? 'Completed'
                                                                : 'Mark As Completed'
                                                        }
                                                    </Button>
                                                </div>
                                            }
                                        </span>
                                        :
                                        <div className='buy-course-error'>
                                            <h1>Unlock the course to access the content</h1>
                                            <img src={buyImage} alt='Buy Course' />
                                            <Link to={`/dashboard/cources/${courseId}/buy`}>
                                                <Button
                                                    type='primary'
                                                    size='large'
                                                    shape='round'
                                                    icon={<ShoppingCartOutlined />}
                                                >
                                                    Buy This Course
                                                </Button>
                                            </Link>
                                        </div>
                                    }
                                </div>

                                <div className='course-right'>
                                    <div className='progress'>
                                        <h2>
                                            <p>Your Study Progress</p>
                                            <span>{
                                                userProfile?.role === 'student' ? completedPercentage + '%' : '100%'
                                            }
                                            </span>
                                        </h2>
                                        <div className='progress-bar'>
                                            <Progress
                                                percent={
                                                    userProfile?.role === 'student' ? completedPercentage : 100
                                                }
                                                status="active"
                                                strokeColor={{
                                                    from: '#108ee9',
                                                    to: '#87d068',
                                                }}
                                            />
                                        </div>
                                        <p>The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt</p>
                                    </div>
                                    <div className='course-content'>
                                        <h2>Course Contents</h2>
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={data}
                                            renderItem={item =>
                                                <List.Item
                                                    className={selectedContentId === item.id ? 'active' : ''}
                                                    onClick={() => setSelectedContentId(item.id)}
                                                >
                                                    <div className='btn' type='link'>
                                                        {selectedContentId === item.id
                                                            ?
                                                            <PauseRoundedIcon
                                                                style={{
                                                                    fontSize: '2rem',
                                                                    color: '#673bf9'
                                                                }}
                                                            />
                                                            :
                                                            <PlayArrowRoundedIcon
                                                                style={{
                                                                    fontSize: '2rem',
                                                                    color: '#673bf9'
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                    <div className='course-content-item'>
                                                        <p>{item.title}</p>
                                                        <span>{item.duration}</span>
                                                    </div>
                                                    <div className='status'>
                                                        {
                                                            item?.status === 'completed'
                                                                ?
                                                                <div className='icon'>
                                                                    <CheckRoundedIcon style={{
                                                                        fontSize: '1.2rem',
                                                                        color: '#fff'
                                                                    }} />
                                                                </div>
                                                                :
                                                                <div
                                                                    className='icon not'
                                                                >
                                                                    <CheckRoundedIcon style={{
                                                                        fontSize: '1.2rem',
                                                                        color: '#fff'
                                                                    }} />
                                                                </div>
                                                        }
                                                    </div>
                                                </List.Item>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <Divider />

                            <div className='course-footer'>
                                <CourseAssessments courseId={courseId} />
                            </div>
                            {/* 
                            {userProfile?.role === 'superadmin' &&
                                <div className='course-footer'>
                                    <WishListUsers courseId={courseId} />
                                </div>
                            } */}
                        </>}
                </div>
            </Spin>

            <CourseUsers
                openCourseUsers={openCourseUsers}
                setOpenCourseUsers={setOpenCourseUsers}
                getCourseUsers={getCourseUsers}
                courseId={courseId}
                courseUsers={courseUsers}
                course={course}
            />
        </div>
    )
}

export default Course