import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAllCoursesByRole, getAllPayments, getAllUsers } from '../../../Constants/Api/functions/function';
import { Button } from 'antd';

import './styles.scss'

import {
    PlusOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import PaymentsTable from './PaymentsTable';
import AddPayment from './AddPayment';

const Payments = () => {
    const allCources = useSelector(state => state.allCources.allCources);
    const allUsers = useSelector((state) => state.allUsers.allUsers);
    const [users, setUsers] = useState([])
    const [cources, setCources] = useState([])
    const [payments, setPayments] = useState([])
    const [openAddPayment, setOpenAddPayment] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        hadleGetPayments();
    }, [])

    useEffect(() => {
        if (allUsers && allUsers.length) {
            setUsers(allUsers)
        } else {
            handleGetUsers();
        }
    }, [allUsers])

    useEffect(() => {
        if (allCources) {
            setCources(allCources)
        } else {
            handleGetCourses();
        }
    }, [allCources]);

    const handleGetCourses = async () => {
        setIsLoading(true);
        try {
            const courses = await getAllCoursesByRole('superadmin');
            setCources(courses);
        } catch (error) {
            console.error('handleGetCourses', error);
        }
        setIsLoading(false);
    }

    const handleGetUsers = async () => {
        setIsLoading(true);
        try {
            const users = await getAllUsers();
            setUsers(users);
        } catch (error) {
            console.error('handleGetUsers', error);
        }
        setIsLoading(false);
    }

    const hadleGetPayments = async () => {
        setIsLoading(true)
        try {
            const payments = await getAllPayments();
            setPayments(payments);
        } catch (error) {
            console.error('hadleGetPayments', error);
        }
        setIsLoading(false)
    }

    const handleExportPayments = () => {
        const headers = Object.keys(payments[0]);

        // Custom headers
        headers[0] = 'Payment ID';
        headers[1] = 'User Name';
        headers[2] = 'Course Name';
        headers[3] = 'Amount';
        headers[4] = 'Payment Date';
        headers[5] = 'Payment Method';
        headers[6] = 'Payment Status';

        // Custom data
        payments.forEach((payment) => {
            payment['Payment ID'] = payment.id;
            payment['User Name'] = users.find(user => user.id === payment.userId)?.name;
            payment['Course Name'] = cources.find(course => course.id === payment.courseId)?.name;
            payment['Amount'] = payment.amount;
            payment['Payment Date'] = payment.paymentDate;
            payment['Payment Method'] = payment.paymentMethod;
            payment['Payment Status'] = payment.paymentStatus;
        });

        const csvContent =
            "data:text/csv;charset=utf-8," +
            headers.join(",") +
            "\n" +
            payments.map((row) => {
                return headers.map((fieldName) => {
                    return row[fieldName];
                }).join(",");
            }
            ).join("\n");


        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "payments.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className='payments'>
            <div className='header'>
                <h1>Payments</h1>
                <div className='actions'>
                    <Button
                        type="default"
                        icon={<DownloadOutlined />}
                        onClick={handleExportPayments}
                    >
                        Export
                    </Button>

                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => setOpenAddPayment(true)}
                    >
                        Add Payment
                    </Button>
                </div>
            </div>

            <div className='payments-table'>
                <PaymentsTable
                    isLoading={isLoading}
                    paymentData={payments}
                    cources={cources}
                    users={users}
                    setPayments={setPayments}
                />
            </div>

            <AddPayment
                isOpen={openAddPayment}
                setOpen={setOpenAddPayment}
                hadleGetPayments={hadleGetPayments}
                users={users}
            />
        </div>
    )
}

export default Payments