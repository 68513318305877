import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

// Antd
import Highlighter from 'react-highlight-words';
import {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Tag } from 'antd';

// API
import { getAllUsers } from '../../../Constants/Api/functions/function';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setAllUsers } from '../../../Reducers/allUsersReducer';

// Styles
import './style.scss';
import AddUser from './AddUser'

const Users = () => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isAddUserOpen, setIsAddUserOpen] = useState(false);
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const searchInput = useRef(null);
    const dispatch = useDispatch();
    const allUsers = useSelector(state => state.allUsers.allUsers);
    const userProfile = useSelector(state => state.userProfile.userProfile);

    useEffect(() => {
        if (userProfile?.role !== 'superadmin') {
            window.location.href = '/dashboard/home'
        }
    }, [userProfile])

    useEffect(() => {
        if (allUsers?.length) {
            setUsers(allUsers)
        } else {
            handleGetAllUsers()
        }
    }, [allUsers])

    useEffect(() => {
        if (users?.length) {
            const data = users?.map((user, index) => {
                return {
                    key: index + 1,
                    id: user?.id,
                    indexNumber: user?.indexNumber,
                    name: user?.name,
                    Role: user?.role,
                    email: user?.email,
                    contactNumber: user?.contactNumber,
                }
            })
            setTableData(data)
        }
    }, [users])

    const handleGetAllUsers = async () => {
        setIsLoading(true)
        try {
            if (userProfile?.role === 'superadmin') {
                const response = await getAllUsers();
                if (response) {
                    dispatch(setAllUsers(response))
                    setUsers(response)
                }
            }
        } catch (error) {
            console.error('getAllUsers', error);
        }
        setIsLoading(false)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Link to={`/dashboard/users/${record.id}/overview`}>
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                </Link>
            ) : (
                <Link to={`/dashboard/users/${record.id}/overview`}>
                    <p
                        style={{
                            color: '#1677ff',
                            cursor: 'pointer',
                        }}
                    >
                        {text}
                    </p>
                </Link>
            ),
    });

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...getNameSearchProps('name'),
        },
        {
            title: 'Index Number',
            dataIndex: 'indexNumber',
            key: 'indexNumber',
            width: '15%',
            sorter: (a, b) => a.indexNumber - b.indexNumber,
            ...getColumnSearchProps('indexNumber'),
        },
        {
            title: 'Contact No',
            dataIndex: 'contactNumber',
            key: 'contactNumber',
            width: '15%',
            ...getColumnSearchProps('contactNumber'),
        },
        {
            title: 'Role',
            dataIndex: 'Role',
            key: 'Role',
            width: '10%',
            filters: [
                {
                    text: 'Super Admin',
                    value: 'superadmin',
                },
                {
                    text: 'Student',
                    value: 'student',
                },
            ],
            onFilter: (value, record) => record.Role.indexOf(value) === 0,
            render: (text) => {
                let color = 'geekblue';
                if (text === 'superadmin') {
                    color = 'volcano';
                }
                if (text === 'student') {
                    color = 'blue';
                }
                return (
                    <Tag color={color} key={text}>
                        {text.toUpperCase()}
                    </Tag>
                );
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            ...getColumnSearchProps('email'),
        },
    ];

    const handleExportUsers = () => {
        const headers = Object.keys(allUsers[0]).filter(key =>
            key !== 'password' && key !== 'createdBy' && key !== 'updatedBy')
        const csvContent =
            "data:text/csv;charset=utf-8," +
            headers.join(",") +
            "\n" +
            allUsers.map((user) => {
                return headers.map((header) => {
                    return user[header];
                })
                    .join(",");
            }).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "users.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return <div className='users'>
        <div className='users-action-buttons'>
            <h2>
                Users List <Tag
                    style={{
                        transform: 'translateY(-3px)',
                        marginLeft: '10px'
                    }}
                    color="blue">{tableData?.length}</Tag>
            </h2>
            {userProfile?.role === 'superadmin' &&
                <div className='actions'>
                    <Button
                        type='default'
                        onClick={handleExportUsers}
                    >
                        <DownloadOutlined />
                        Export Users
                    </Button>

                    <Button
                        type='primary'
                        onClick={() => setIsAddUserOpen(true)}
                    >
                        <PlusOutlined />
                        Add User
                    </Button>
                </div>}
        </div>
        <Spin
            spinning={isLoading}
            tip="Loading Users..."
        >
            <Table
                className='users_table'
                columns={columns}
                dataSource={tableData}
                pagination={{
                    pageSize: 20,
                    showSizeChanger: false,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} users`,
                }}
            />
        </Spin>
        <AddUser
            isOpen={isAddUserOpen}
            setOpen={setIsAddUserOpen}
            handleGetAllUsers={handleGetAllUsers}
        />
    </div>;
};
export default Users;