import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Input, List, Modal, Popconfirm, Row, Select, Spin, Upload, message, notification } from 'antd';

import {
    PlusOutlined,
    DeleteOutlined,
    ExclamationCircleFilled
} from '@ant-design/icons';


import image from '../../1695793881259.jpeg'
import TextArea from 'antd/es/input/TextArea';

import './style.scss';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { deleteCourse, deleteCourseContent, editCourse, getCourseById, getCourseContentByRoleAndCourseId } from '../../../../../Constants/Api/functions/function';
import AddContent from './AddContent';
import EditContent from './EditContent';
import { BLOB_SAS_URL } from '../../../../../Constants/Api/azure/azure';
import { BlobServiceClient } from '@azure/storage-blob';

const { Option } = Select;

const EditCourse = () => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedContentId, setSelectedContentId] = useState();
    const [selectedContentData, setSelectedContentData] = useState();
    const [openEdit, setOpenEdit] = useState(false);
    const [isAddContentOpen, setisAddContentOpen] = useState(false);
    const [courseData, setCourseData] = useState();
    const [data, setData] = useState();
    const courseId = useParams().id
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allCources = useSelector(state => state.allCources.allCources);
    const { confirm } = Modal;
    const fromRef = useRef();
    const [fileList, setFileList] = useState([]);
    const [isCoverImageChanged, setIsCoverImageChanged] = useState(false);

    useEffect(() => {
        if (userProfile?.role !== 'superadmin' && userProfile?.role !== 'admin') {
            window.location.href = '/dashboard/home'
        }
    }, [userProfile])

    useEffect(() => {
        if (allCources?.length > 0) {
            const course = allCources.find(course => course.id === courseId);
            setCourseData(course);
        } else {
            handelGetCourseById();
        }
    }, [allCources, courseId]);

    useEffect(() => {
        getCourseContents();
    }, [courseData]);

    useEffect(() => {
        const content = data?.find(content => content.id === selectedContentId);
        setSelectedContentData(content);
    }, [selectedContentId]);

    useEffect(() => {
        if (courseData && fromRef.current) {
            fromRef.current.setFieldsValue({
                name: courseData.name,
                description: courseData.description,
                coverImage: courseData.coverImage,
                isPublished: courseData.isPublished ? 'true' : 'false',
                isAppearOnHome: courseData.isAppearOnHome ? 'true' : 'false',
                price: courseData.price,
                orderNumber: courseData.orderNumber,
                tags: courseData.tags?.split(','),
                startDate: courseData.startDate,
                startTime: courseData.startTime,
                installments: courseData.installments,
                isShowOnHome: courseData.isShowOnHome ? 'true' : 'false',
                description1: courseData.description1,
                whatsappGroupLink: courseData.whatsappGroupLink,
                videoUrl: courseData.videoUrl,
                status: courseData.status === 'completed' ? 'completed' : 'ongoing',
            });

            setFileList([{
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: courseData.coverImage,
            }]);
        }
    }, [courseData]);

    const showAddContent = () => {
        setisAddContentOpen(true);
    };

    const onFinish = async (values) => {
        setIsLoading(true);

        Object.keys(values).forEach(key => (values[key] === undefined || values[key] === '') && delete values[key]);

        values.id = courseId;

        if (values.isPublished === 'true') {
            values.isPublished = true;
        } else {
            values.isPublished = false;
        }

        if (values.isAppearOnHome === 'true') {
            values.isAppearOnHome = true;
        } else {
            values.isAppearOnHome = false;
        }

        if (values.tags) {
            values.tags = values.tags.join(',');
        }

        if (isCoverImageChanged) {
            if (fileList.length < 1) {
                openEditNotification('error',
                    'Upload course cover image',
                    'Please upload course cover image to continue'
                );
                return;
            }

            const blobName = `${values.name.replace(/\s/g, '')}.png`;
            const res = await uploadFile(blobName);
            values.coverImage = res?.blockBlobClient?.url;
        }

        try {
            const res = await editCourse(values);
            if (res && res.status === 200) {
                openEditNotification('success',
                    'Course updated successfully',
                    'The course has been updated successfully'
                );
                handelGetCourseById();
            } else {
                openEditNotification('error',
                    'Course update failed',
                    'The course update failed'
                );
            }
        }
        catch (error) {
            console.error('editCourse', error);
        }

        setIsLoading(false);
    }

    const uploadFile = async (blobName) => {
        try {
            const blobSasUrl = BLOB_SAS_URL;
            const container = 'course-thumbnails';

            const containerClient = new BlobServiceClient(blobSasUrl).getContainerClient(container);

            const res = await containerClient.uploadBlockBlob(
                `${blobName}`,
                fileList[0].originFileObj,
                fileList[0].size
            );
            return res
        } catch (error) {
            console.log("error", error)
        }
    }

    const deleteContent = async (id) => {
        setIsLoading(true);
        try {
            const response = await deleteCourseContent(id);
            if (response && response.status === 200) {
                openDeleteNotification('success',
                    'Course content deleted successfully',
                    'The course content has been deleted successfully'
                );
                await getCourseContents();
            } else {
                openDeleteNotification('error',
                    'Course content deletion failed',
                    'The course content deletion failed'
                );
            }
        } catch (error) {
            console.error('deleteCourse', error);
            openDeleteNotification('error',
                'Course content deletion failed',
                `The course content deletion failed. ${error}`
            );
        }
        setIsLoading(false);
    }

    const handelGetCourseById = async () => {
        try {
            const response = await getCourseById(courseId);
            setCourseData(response[0]);
        } catch (error) {
            console.error('handelGetCourseById', error);
        }
    }

    const getCourseContents = async () => {
        setIsLoading(true);
        try {
            const response = await getCourseContentByRoleAndCourseId(courseId, userProfile.role, userProfile.id);
            if (response) {
                setData(response.map((item, index) => {
                    return {
                        id: item.id,
                        title: item.title,
                        description: item.description,
                        createdAt: item.createdAt,
                        videoUrl: item.videoUrl,
                        driveUrl: item.driveUrl,
                        isPublished: item.isPublished,
                        isFree: item.isFree,
                    }
                }))

                setSelectedContentId(response[0].id);
            }
        } catch (error) {
            console.error('getCourseContents', error);
        }
        setIsLoading(false);
    }

    const handleDeleteCourse = async () => {
        setIsLoading(true);
        try {
            const response = await deleteCourse(courseId);
            if (response && response.status === 200) {
                openDeleteNotification('success',
                    'Course deleted successfully',
                    'The course has been deleted successfully'
                );

                setTimeout(() => {
                    window.location.href = '/dashboard/cources';
                }, 2000);

            } else {
                openDeleteNotification('error',
                    'Course deletion failed',
                    'The course deletion failed'
                );
            }
        } catch (error) {
            console.error('deleteCourse', error);
            openDeleteNotification('error',
                'Course deletion failed',
                `The course deletion failed. ${error}`
            );
        }
        setIsLoading(false);
    }

    const showDeleteConfirm = async () => {
        confirm({
            title: 'Are you sure delete this course?',
            icon: <ExclamationCircleFilled />,
            content: 'This action cannot be undone. Are you sure you want to delete this course? If you delete this course, all the contents of this course will be deleted.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                await handleDeleteCourse(courseId);
            },
        });
    };

    const openDeleteNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    const openEditNotification = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        setIsCoverImageChanged(true);
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <div className='edit-course'>
            <Spin spinning={isLoading}
                size="large"
                tip="Loading..."
            >
                {contextHolder}
                <div className='edit-header'>
                    <h1>Edit Course</h1>
                    <Button
                        type='primary'
                        danger
                        className='btn'
                        onClick={showDeleteConfirm}
                    >
                        <DeleteOutlined />
                        <span>Delete Course</span>
                    </Button>
                </div>
                <div className='edit-body'>
                    <div className='edit-left'>
                        <div className='thumbnail'>
                            <img
                                src={courseData?.coverImage || image}
                                alt='thumbnail' />
                        </div>
                        <div className='edit-course'
                            style={{
                                marginTop: '20px'
                            }}
                        >
                            <Form
                                layout="vertical"
                                onFinish={onFinish}
                                ref={fromRef}
                            >
                                <Form.Item
                                    name="name"
                                    label="Course Name"
                                >
                                    <Input
                                        showCount
                                        maxLength={200}
                                        placeholder="Please enter course's name" />
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                    label="Course Description"
                                >
                                    <TextArea
                                        showCount
                                        maxLength={1000}
                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                        placeholder="Please enter course's discription" />
                                </Form.Item>
                                <Form.Item
                                    name="price"
                                    label="Course Price (LKR)"
                                >
                                    <Input
                                        type="number"
                                        placeholder="Please enter course's price" />
                                </Form.Item>
                                <Form.Item
                                    name="orderNumber"
                                    label="Order Number (The order of the course when displaying)"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter course's order number",
                                        },
                                    ]}
                                >
                                    <Input
                                        type="number"
                                        placeholder="Please enter course's order number" />
                                </Form.Item>

                                <Form.Item
                                    name="tags"
                                    label="Select course tags"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select course's tags",
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please select"
                                        options={[
                                            {
                                                label: 'Video Tutorials',
                                                value: 'VideoTutorials'
                                            },
                                            {
                                                label: 'Exams',
                                                value: 'Exams'
                                            },
                                            {
                                                label: 'Training and Courses',
                                                value: 'TrainingAndCourses'
                                            },
                                            {
                                                label: 'News',
                                                value: 'News'
                                            },
                                        ]}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="coverImage"
                                    label="Course cover image"
                                >
                                    <Upload
                                        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={onChange}
                                        onPreview={onPreview}
                                        beforeUpload={file => {
                                            const isPNG = (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg');
                                            if (!isPNG) {
                                                message.error(`${file.name} is not a png file`);

                                                return isPNG || Upload.LIST_IGNORE;
                                            } else {
                                                return false;
                                            }
                                        }}
                                    >
                                        {fileList.length < 1 && '+ Upload'}
                                    </Upload>
                                </Form.Item>

                                <Form.Item
                                    name='startDate'
                                    label="Course Start Date"
                                >
                                    <Input
                                        type="date"
                                        placeholder="Please enter course's start date" />
                                </Form.Item>

                                <Form.Item
                                    name='startTime'
                                    label="Course Start Time"
                                >
                                    <Input
                                        type="time"
                                        placeholder='Please enter course start time'
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='installments'
                                    label="Number of Installments"
                                >
                                    <Input
                                        type="number"
                                        placeholder='Please enter number of installments'
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='isShowOnHome'
                                    label="Show on Home Page"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select course's show on home page status",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select course's show on home page status"
                                    >
                                        <Option value="true">Yes</Option>
                                        <Option value="false">No</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name='description1'
                                    label="Description 1"
                                >
                                    <TextArea
                                        showCount
                                        maxLength={1000}
                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                        placeholder="Please enter course's discription" />
                                </Form.Item>

                                <Form.Item
                                    name='whatsappGroupLink'
                                    label="Whatsapp Group Link"
                                >
                                    <Input
                                        placeholder='Please enter whatsapp group link'
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='videoUrl'
                                    label="Video URL"
                                >
                                    <Input
                                        placeholder='Please enter video url'
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="isPublished"
                                    label="Course is Published"
                                >
                                    <Select
                                        placeholder="Please select course's published status"
                                    >
                                        <Option value="true">Published</Option>
                                        <Option value="false">Not Published</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name='isAppearOnHome'
                                    label="Appear on Home"
                                >
                                    <Select
                                        placeholder="Please select course's appear on home status"
                                    >
                                        <Option value="true">Appear on Home</Option>
                                        <Option value="false">Don't Appear on Home</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name='status'
                                    label="Course Status"
                                >
                                    <Select
                                        placeholder="Please select course's status"
                                    >
                                        <Option value="ongoing">Ongoing</Option>
                                        <Option value="completed">Completed</Option>
                                    </Select>
                                </Form.Item>


                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Button
                                            style={{ width: '100%' }}
                                            type="primary" htmlType="submit">
                                            Update Course
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                    <div className='edit-right'>
                        <div className='top'>
                            <h2>Course Content</h2>
                            <Button
                                onClick={showAddContent}
                                type='primary'><PlusOutlined /> Add Content</Button>
                        </div>
                        <div className='content'>
                            <List
                                size="small"
                                bordered
                                dataSource={data}
                                renderItem={item =>
                                    <List.Item
                                        style={{
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Row
                                            gutter={16}
                                        >
                                            <div className='no-edit'>
                                                <section>
                                                    <p>Content Title:</p>
                                                    <span>
                                                        {item.title || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Description:</p>
                                                    <span>
                                                        {item.description || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Video ID:</p>
                                                    <span>
                                                        {item.videoUrl || 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Drive URL:</p>
                                                    <span>
                                                        {item.driveUrl || 'N/A'}
                                                    </span>
                                                </section>

                                                <section>
                                                    <p>Created At:</p>
                                                    <span>
                                                        {item.createdAt ? new Date(item.createdAt).toDateString() : 'N/A'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Is Free:</p>
                                                    <span
                                                        style={{
                                                            color: item.isFree ? 'green' : 'red'
                                                        }}
                                                    >
                                                        {item.isFree ?
                                                            'Free'
                                                            : 'Paid'}
                                                    </span>
                                                </section>
                                                <section>
                                                    <p>Is Published:</p>
                                                    <span
                                                        style={{
                                                            color: item.isPublished ? 'green' : 'red'
                                                        }}
                                                    >
                                                        {item.isPublished ?
                                                            'Published'
                                                            : 'Not Published'}
                                                    </span>
                                                </section>
                                            </div>
                                        </Row>

                                        <Row
                                            gutter={16}
                                        >
                                            <Col>
                                                <Button
                                                    onClick={
                                                        () => {
                                                            setOpenEdit(true);
                                                            setSelectedContentId(item.id);
                                                        }
                                                    }
                                                    style={{ width: '100%' }}
                                                    type="default"
                                                >
                                                    Edit
                                                </Button>
                                            </Col>

                                            <Col >
                                                <Popconfirm
                                                    title="Delete the content."
                                                    description="This action cannot be undone. Are you sure you want to delete this content?"
                                                    onConfirm={deleteContent.bind(this, item.id)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        style={{ width: '100%' }}
                                                        type="default"
                                                        danger>
                                                        <DeleteOutlined />
                                                        Delete
                                                    </Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                }
                            />
                        </div>
                    </div>
                </div>
            </Spin>

            <AddContent
                isOpen={isAddContentOpen}
                setOpen={setisAddContentOpen}
                courseId={courseId}
                hadleGetCourceContents={getCourseContents}
            />

            <EditContent
                isOpen={openEdit}
                setOpen={setOpenEdit}
                contentData={selectedContentData}
                courseId={courseId}
                hadleGetCourceContents={getCourseContents}
            />
        </div>
    )
}

export default EditCourse