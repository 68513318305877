import React, { useEffect, useState } from 'react'

import { getCourcesAndCorseWishListUsers, } from '../../../Constants/Api/functions/function';
import { List, Spin } from 'antd';

import './styles.scss'

const WishListAdmin = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [wishListData, setWishListData] = useState();

    useEffect(() => {
        getAllWishListsdata();
    }, [])

    const getAllWishListsdata = async () => {
        setIsLoading(true);
        try {
            const response = await getCourcesAndCorseWishListUsers();
            console.log('response', response)
            setWishListData(response);
        } catch (error) {
            console.log(error);
        }

        setIsLoading(false);
    }

    return (
        <div className='adim-wishlist-page'>
            <div className='notes-header'>
                <h1>Wish List Users</h1>
            </div>

            <Spin spinning={isLoading}>
                <div className='wishlist-list'>
                    <List
                        dataSource={wishListData}
                        itemLayout='horizontal'
                        renderItem={item => (
                            <List.Item>
                                <div className='wishlist-item'>
                                    <div className='student-data'>
                                        <h4>Course Name: {item?.course?.name}</h4>
                                    </div>

                                    <div className='course-data'>
                                        <h4>
                                            Wish List Users
                                        </h4>
                                        {item?.users.map(user => (
                                            <div key={user.id} className='course'>
                                                <p>User Name: {user.name}</p>
                                                <p>Index Number: {user.indexNumber}</p>
                                                <p>Contact Number: {user.contactNumber}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default WishListAdmin