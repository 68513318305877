import React, { useEffect, useState } from 'react';

// Antd
import {
    PlusOutlined,
    WhatsAppOutlined,
    HeartFilled
} from '@ant-design/icons';
import {
    AutoComplete,
    Button,
    Input,
    List,
    Radio,
    Select,
    Spin,
    Tag,
    Tooltip
} from 'antd';
import { Link } from 'react-router-dom';

// API
import { addCourseToWishList, getAllCoursesByRole, getUserById, getUsersCourses, removeCourseFromWishList } from '../../../Constants/Api/functions/function';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setAllCources } from '../../../Reducers/allCourcesReducer';

// Styles
import './style.scss';

import AddCource from './AddCource';
import { DEFAULT_USER_PROFILE_IMAGE } from '../../../Constants/Api/azure/azure';
import { setUsersCources } from '../../../Reducers/usersCourcesReducer';
import { setUserProfile } from '../../../Reducers/userProfileReducer';

const Cources = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAddCourseOpen, setIsAddCourseOpen] = useState(false);
    const [radioValue, setRadioValue] = useState(1);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const allCources = useSelector(state => state.allCources.allCources);
    const [userCources, setUserCources] = useState();
    const [data, setData] = useState();
    const [coursesData, setCoursesData] = useState()
    const dispatch = useDispatch();

    useEffect(() => {
        if (data) {
            const sortedData = [...data].sort((a, b) => {
                return a?.orderNumber - b?.orderNumber;
            });
            setCoursesData(
                sortedData.map((item) => {
                    return {
                        id: item?.id,
                        title: item?.name,
                        discription: item?.description,
                        isPublished: item?.isPublished,
                        thumbnail: item?.coverImage,
                        price: item?.price,
                        authorName: item?.createdBy?.name,
                        authorImage: item?.createdBy?.profileImage,
                        orderNumber: item?.orderNumber,
                        whatsappGroupLink: item?.whatsappGroupLink,
                        tags: item?.tags,
                        status: item?.status
                    };
                })
            );
        }
    }, [data, userCources]);

    useEffect(() => {
        if (userProfile?.role !== 'superadmin') {
            handleGetUserCources();
        }
    }, []);

    useEffect(() => {
        hadleGetCources();
    }, []);

    const hadleGetCources = async () => {
        setIsLoading(true);
        if (userProfile) {
            let response = await getAllCoursesByRole(userProfile.role);
            if (response) {
                if (userProfile.role === 'superadmin') {
                    const ongoingCourses = response?.filter((course) => {
                        return course?.status !== 'completed';
                    });
                    setData(ongoingCourses)
                } else {
                    const ongoingCourses = response?.filter((course) => {
                        return course?.status !== 'completed';
                    });
                    setData(ongoingCourses)
                }
                dispatch(setAllCources(response));
            }
        }
        setIsLoading(false);
    }

    const handleGetUserCources = async () => {
        setIsLoading(true)
        try {
            const userCources = await getUsersCourses(userProfile?.id)
            setUserCources(userCources)
            dispatch(setUsersCources(userCources))
        } catch (error) {
            console.error('handleGetUserCources', error)
        }
        setIsLoading(false)
    }

    const onChangeRadio = (e) => {
        setRadioValue(e.target.value);
        if (e.target.value === 1) {
            const ongoingCourses = allCources?.filter((course) => {
                return course?.status !== 'completed';
            });
            setData(ongoingCourses);
        } else if (e.target.value === 3) {
            const filteredData = allCources?.filter((item) => {
                return item?.price === 0;
            });
            setData(filteredData);
        } else if (e.target.value === 4) {
            const ongoingCourses = allCources?.filter((course) => {
                return course?.status === 'completed';
            });
            setData(ongoingCourses);
        } else {
            const filteredData = allCources?.filter((item) => {
                return userCources?.find((course) => course?.id === item?.id);
            }
            );

            setData(filteredData);
        }
    }

    const filterBytags = (value) => {
        if (value === 'All') {
            setData(allCources);
        } else {
            const filteredData = data?.filter((item) => {
                const tagsArray = item?.tags?.split(',');
                return tagsArray?.find((tag) => tag === value);
            });

            setData(filteredData);
        }
    }

    const hnadlegetUserById = async (user) => {
        try {
            const response = await getUserById(user.id)
            if (response?.id) {
                dispatch(setUserProfile(response))
                localStorage.setItem('digital-academy-user', JSON.stringify(response))
            }
        } catch (error) {
            console.log('handlegetUserById -> error', error)
        }
    }

    const renderTags = (tags) => {
        return tags?.split(',').map((tag) => {
            if (tag === 'VideoTutorials') {
                return <Tag color="blue">Video Tutorials</Tag>
            } else if (tag === 'Exams') {
                return <Tag color="blue">Exams</Tag>
            } else if (tag === 'TrainingAndCourses') {
                return <Tag color="blue">Training and Courses</Tag>
            } else if (tag === 'News') {
                return <Tag color="blue">News</Tag>
            } else if (tag === 'Seminar') {
                return <Tag color="blue">Seminar</Tag>
            }
        })
    }

    const isCourseInWishList = (courseId) => {
        const userWishList = userProfile?.courseWishlist;
        const wishlistIds = userWishList?.split(',');
        wishlistIds?.pop();

        return wishlistIds?.find((id) => id === courseId);
    }

    const isCourseBought = (courseId) => {
        let res = false;
        res = userCources?.find((course) => course?.id === courseId);

        if (allCources?.find((course) => course?.id === courseId)?.price === 0) {
            res = true;
        }

        return res;
    }


    const handleAddOrRemoveFromWishList = async (courseId) => {
        setIsLoading(true);
        try {
            const isInWishList = isCourseInWishList(courseId);
            if (isInWishList) {
                const res = await removeCourseFromWishList(userProfile.id, courseId);
                if (res) {
                    await hnadlegetUserById(userProfile);
                    await hadleGetCources();
                }
            } else {
                const res = await addCourseToWishList(userProfile.id, courseId);
                if (res) {
                    await hnadlegetUserById(userProfile);
                    await hadleGetCources();
                }
            }

        } catch (error) {
            console.log(error);
        }

        setIsLoading(false);
    }

    return <div className='cources'>
        <div className='cources-action-buttons'>
            <div className='filters'>
                <div className='filter-search'>
                    <h2>
                        All Courses
                    </h2>
                    <div className='filter-inputs'>
                        <AutoComplete
                            popupMatchSelectWidth={252}
                            options={allCources?.map((item) => {
                                return {
                                    value: item.name,
                                    label: item.name,
                                }
                            })}
                            size="large"
                            onSelect={(value) => {
                                if (value) {
                                    const filteredData = allCources?.filter((item) => {
                                        return item?.name?.toLowerCase().includes(value.toLowerCase());
                                    });
                                    setData(filteredData);
                                } else {
                                    setData(allCources);
                                }
                            }}
                        >
                            <Input.Search size="large"
                                placeholder="Search by title"
                                enterButton
                                onSearch={(value) => {
                                    if (value) {
                                        const filteredData = allCources?.filter((item) => {
                                            return item?.name?.toLowerCase().includes(value.toLowerCase());
                                        });
                                        setData(filteredData);
                                    } else {
                                        setData(allCources);
                                    }
                                }}
                                onChange={(e) => {
                                    if (!e.target.value) {
                                        setData(allCources);
                                    } else {
                                        const filteredData = allCources?.filter((item) => {
                                            return item?.name?.toLowerCase().includes(e.target.value.toLowerCase());
                                        });
                                        setData(filteredData);
                                    }
                                }}
                            />
                        </AutoComplete>
                    </div>
                </div>
                <div className='radio-buttons'>
                    <Radio.Group
                        onChange={onChangeRadio}
                        value={radioValue}
                    >
                        <Radio value={1}>
                            All Courses
                        </Radio>
                        <Radio value={2}>
                            My Courses
                        </Radio>
                        <Radio value={3}>
                            Free Courses
                        </Radio>
                        {userProfile?.role === 'superadmin' &&
                            <Radio value={4}>
                                Completed
                            </Radio>
                        }
                    </Radio.Group>
                </div>

                <div className='radio-buttons'>
                    <Select placeholder="Filter by tags"
                        style={{
                            minWidth: '150px'
                        }}
                        onChange={(value) => filterBytags(value)}
                    >
                        <Select.Option value={'All'}>All</Select.Option>
                        <Select.Option value={'VideoTutorials'}>Video Tutorials</Select.Option>
                        <Select.Option value={'Exams'}>Exams</Select.Option>
                        <Select.Option value={'TrainingAndCourses'}>Training and Courses</Select.Option>
                        <Select.Option value={'News'}>News</Select.Option>
                        <Select.Option value={'Seminar'}>Seminar</Select.Option>
                    </Select>
                </div>
            </div>
            {userProfile?.role === 'superadmin' &&
                <Button
                    type='primary'
                    onClick={() => setIsAddCourseOpen(true)}
                >
                    <PlusOutlined />
                    Add Cource
                </Button>}
        </div>
        <Spin
            spinning={isLoading}
            tip="Loading Courses..."
        >
            <div className='cources-body'>
                <Spin
                    tip="Loading..."
                    spinning={isLoading}
                >
                    <List
                        grid={{
                            gutter: 30,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 2,
                            xl: 3,
                            xxl: 4,
                        }}
                        dataSource={coursesData}
                        renderItem={(item) => (
                            <List.Item>
                                <div className='course-card'>
                                    <div className='card-image-container'>
                                        <img
                                            src={
                                                item?.thumbnail
                                                    ? item?.thumbnail
                                                    : require('./1695793881259.jpeg')
                                            } alt=""
                                        />
                                        <div className='cource-card-header-buttons'>
                                            {userProfile?.role === 'student' ?
                                                <>
                                                    {!isCourseBought(item?.id) && <div
                                                        className={isCourseInWishList(item.id) ? 'wishlist-In' : 'wishlist-Out'}
                                                    >
                                                        <Tooltip
                                                            title={isCourseInWishList(item.id)
                                                                ?
                                                                'Remove from wishlist'
                                                                :
                                                                'Request to follow this Course | මෙම පාඨමාලාව හැදෑරීමට ඉල්ලුම් කිරීමට කැමැත්තෙමි.'
                                                            }
                                                        >
                                                            <Button
                                                                type='link'
                                                                onClick={() => handleAddOrRemoveFromWishList(item.id)}
                                                            >
                                                                <HeartFilled />
                                                            </Button>
                                                        </Tooltip>
                                                    </div>}
                                                    <div
                                                        className='duration'
                                                        style={{
                                                            backgroundColor: item?.status === 'completed' && '#4CAF50'
                                                        }}
                                                    >
                                                        <p>
                                                            {
                                                                item?.status === 'completed' && 'Completed'
                                                            }
                                                        </p>
                                                    </div>
                                                </>
                                                :
                                                <div
                                                    className='duration'
                                                    style={{
                                                        backgroundColor: item?.isPublished ? '#4CAF50' : '#f44336'
                                                    }}
                                                >
                                                    <p>
                                                        {
                                                            item?.isPublished ? 'Published' : 'Not Published'
                                                        }
                                                    </p>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='card-description'>
                                            <div className='price'>
                                                {
                                                    item?.price === 0
                                                        ?
                                                        <p className='free-text'>
                                                            Free
                                                        </p>
                                                        :
                                                        <p>
                                                            {item?.price} LKR
                                                        </p>
                                                }
                                            </div>

                                            <div className='author'>
                                                <img
                                                    src={
                                                        item?.authorImage
                                                            ? item?.authorImage
                                                            : DEFAULT_USER_PROFILE_IMAGE
                                                    } alt=""
                                                />
                                                <h3>
                                                    {item?.authorName}
                                                </h3>
                                            </div>
                                        </div>

                                        <h3 className='title'>
                                            {item?.title}
                                        </h3>

                                        <div className="tags">
                                            {renderTags(item?.tags)}
                                        </div>
                                    </div>

                                    <div className='card-footer'>
                                        <div className='button-continer'>
                                            <>
                                                {userProfile?.role !== 'superadmin' ?
                                                    <Link
                                                        className='enroll-now'
                                                        to={`/dashboard/cources/${item.id}`}><Button
                                                            type='link'
                                                        >
                                                            {
                                                                userCources?.find((course) => course?.id === item?.id)
                                                                    ?
                                                                    'Continue'
                                                                    :
                                                                    'Enroll Now'
                                                            }
                                                        </Button>
                                                    </Link>
                                                    :
                                                    <Link
                                                        className='enroll-now'
                                                        to={`/dashboard/cources/${item.id}`}>
                                                        <Button
                                                            type='link'
                                                        >
                                                            View
                                                        </Button>
                                                    </Link>
                                                }
                                            </>
                                            <>
                                                {
                                                    item?.whatsappGroupLink &&
                                                    <a href={item?.whatsappGroupLink} target='_blank' rel="noreferrer">
                                                        <Button type='primary' style={{
                                                            backgroundColor: '#25d366',
                                                        }}>
                                                            <WhatsAppOutlined />
                                                            Join Whatsapp Group
                                                        </Button>
                                                    </a>
                                                }
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                </Spin>
            </div>
        </Spin>

        <AddCource
            isOpen={isAddCourseOpen}
            setOpen={setIsAddCourseOpen}
            hadleGetCources={hadleGetCources}
        />
    </div>;
};
export default Cources;